<template>
    <div>
        <div class="form" v-if="isStep(0)">
            <div>
                ファイルを選択
                <input @change="changeFile" ref="rfafile" type="file" />
            </div>
            <div>
                <OpRadioGroup
                    v-model="upmode"
                    v-slot="{ item }"
                    :choices="upmodelist"
                    :itemKey="item => item.key"
                >
                    {{ item.name }}
                </OpRadioGroup>
            </div>
            <div>
                <input @click="regist" type="button" value="Upload" />
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message">
                登録しました
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditorMixin from "@/views/EditorMixin.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";

export default {
    name: "DataLoader",
    mixins: [EditorMixin],
    components: {
        OpRadioGroup
    },
    model: {
        prop: "model"
    },
    props: {
        model: {
            default: function() {
                return {};
            },
            type: Object
        },
        mode: String
    },
    data: function() {
        return {
            requesting: false,
            upmode: "a",
            upmodelist: [
                { key: "a", name: "追加" },
                { key: "u", name: "上書き更新" },
                { key: "c", name: "書き換え" }
            ],
            uploadfile: {}
        };
    },
    methods: {
        changeFile(e) {
            const files = e.target.files || e.dataTransfer.files;
            // ファイルが選択されたら変数に入れる
            this.uploadfile = files[0];
        },
        // 送信アクション

        async regist() {
            var params = new FormData();
            // FormDataにアップロードするファイルを設定
            params.append("file", this.uploadfile);
            params.append("upmode", this.upmode);
            // API実行
            this.postMultipartData(this.makeurl("ldr"), params, () => {
                this.setStep(2);
            });
        }
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid gray;
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}

.emp-form {
    position: relative;
    display: grid;
    grid-template-areas: "emp-id" "emp-name-long";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.emp-confirm {
    position: relative;
    display: grid;
    grid-template-areas: "emp-id" "emp-name-long";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.emp-id {
    grid-area: emp-id;
}
.emp-name-long {
    grid-area: emp-name-long;
}
</style>
