<template>
    <div class="target-edit-panel">
        <div class="header content"></div>
        <div class="form" v-if="isStep(0)">
            <div class="target-edit-form">
                <div class="item target-date">
                    <OpDateTextField
                        label="目標日"
                        v-model="target_date"
                        name="target_date"
                        required
                        @changed-valid="changedValid"
                        @input="loaddata(target_date)"
                        ref="target_date"
                    />
                    ※
                    目標日を変更すると、以下の各値は現在登録されている値にリセットされます。
                </div>
                <div class="item datalist">
                    <div v-for="(obj, ix) in dlist" :key="ix" class="datarow">
                        <div class="dept">
                            {{ obj.department.department_name_short }}
                        </div>
                        <div class="amo">
                            <OpNumberTextField
                                label="目標金額（現在）"
                                v-model="obj.target_amount"
                                :name="'target_amount_' + ix"
                                readonly
                            />
                        </div>
                        <div class="amo-plus">
                            +
                        </div>
                        <div class="amo-diff">
                            <OpNumberTextField
                                label=""
                                v-model="obj.target_amount_diff"
                                :max="999999999999 - obj.target_amount"
                                :min="-1 * obj.target_amount"
                                :name="'target_amount_diff_' + ix"
                                @changed-valid="changedValid"
                                @liveinput="obj.calcTargetAmountAfter"
                            />
                        </div>
                        <div class="amo-eq">
                            =
                        </div>
                        <div class="amo-after">
                            <OpNumberTextField
                                label="目標金額（計）"
                                v-model="obj.target_amount_after"
                                :name="'target_amount_after_' + ix"
                                readonly
                            />
                        </div>
                        <div class="mam">
                            <OpNumberTextField
                                label="工数（現在）"
                                v-model="obj.man_month"
                                :name="'man_month_' + ix"
                                :decimals="2"
                                readonly
                            />
                        </div>
                        <div class="mam-plus">
                            +
                        </div>
                        <div class="mam-diff">
                            <OpNumberTextField
                                label=""
                                v-model="obj.man_month_diff"
                                :max="99999.99 - obj.man_month"
                                :min="-1 * obj.man_month"
                                :name="'man_month_diff_' + ix"
                                :decimals="2"
                                @liveinput="obj.calcManMonthAfter"
                                @changed-valid="changedValid"
                            />
                        </div>
                        <div class="mam-eq">
                            =
                        </div>
                        <div class="mam-after">
                            <OpNumberTextField
                                label="工数（計）"
                                v-model="obj.man_month_after"
                                :name="'man_month_after_' + ix"
                                :decimals="2"
                                readonly
                            />
                        </div>
                        <div class="tnote">
                            <OpTextField
                                label="備考"
                                v-model="obj.target_note"
                                name="target_note"
                                maxlength="80"
                                @changed-valid="changedValid"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)">
            <table class="confirm">
                <tbody>
                    <tr>
                        <th>目標日</th>
                        <td>{{ target_date | date }}</td>
                    </tr>
                </tbody>
            </table>

            <table class="confirm">
                <tbody v-for="(obj, ix) in dlist" :key="ix">
                    <tr>
                        <th class="dept" rowspan="3">
                            {{ obj.department.department_name_short }}
                        </th>
                        <th>
                            目標金額
                        </th>
                        <td class="amo">
                            {{ obj.target_amount | amount }}
                        </td>
                        <td class="amo-plus">
                            <span v-if="obj.target_amount_diff < 0">
                                -
                            </span>
                            <span v-else>
                                +
                            </span>
                        </td>
                        <td class="amo-plus">
                            {{ obj.target_amount_diff | abs | amount }}
                        </td>
                        <td class="amo-eq">
                            =
                        </td>
                        <td class="amo-after">
                            {{ obj.target_amount_after | amount }}
                        </td>
                    </tr>
                    <tr>
                        <th>工数</th>
                        <td class="mam">
                            {{ obj.man_month | amount(0, 2) }}
                        </td>
                        <td class="mam-plus">
                            <span v-if="obj.man_month_diff < 0">
                                -
                            </span>
                            <span v-else>
                                +
                            </span>
                        </td>
                        <td class="mam-plus">
                            {{ obj.man_month_diff | abs | amount(0, 2) }}
                        </td>
                        <td class="mam-eq">
                            =
                        </td>
                        <td class="mam-after">
                            {{ obj.man_month_after | amount(0, 2) }}
                        </td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td class="tnote" colspan="5">
                            {{ obj.target_note }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click="addData">
                        登録
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message" v-if="mode == 'create'">
                目標 ({{ d.target_date | date }}) を登録しました。
            </div>
            <div class="message" v-if="mode == 'update'">
                目標 ({{ d.target_date | date }}) の情報を修正しました。
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import EditorMixin from "@/views/EditorMixin.vue";
import OpDateTextField from "@/components/OpDateTextField.vue";
// import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import OpNumberTextField from "@/components/OpNumberTextField.vue";
import OpTextField from "@/components/OpTextField.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "TargetEditor",
    mixins: [EditorMixin],
    components: {
        OpDateTextField,
        // OpSelectDepartment,
        OpNumberTextField,
        OpTextField
    },
    model: {
        prop: "model"
    },
    props: {
        model: Object,
        mode: String
    },
    data: function() {
        return {
            d: {},
            target_date: this.model.target_date,
            dlist: this.initModel()
        };
    },
    // computed: {
    //     calcTargetAmount: function(obj) {
    //         return obj.target_amount + obj.target_amount_diff;
    //     }
    // },
    methods: {
        initModel: function() {
            // let ret = { ...this.model };
            // ret.target_department = {
            //     department_id: ret.department_id,
            //     department_name_long: ret.department_name_long
            // };
            // return ret;
            return [];
        },
        calcTargetAmount: function(obj, val) {
            obj.target_amount_after = this.calcAfter(obj.target_amount, val);
        },
        calcManMonth: function(obj, val) {
            obj.man_month_after = this.calcAfter(obj.man_month, val);
        },
        calcAfter: function(amo, val) {
            if (amo) {
                amo = parseInt(amo);
            } else {
                amo = 0;
            }
            // let diff = obj.target_amount_diff;
            let diff = val;
            if (diff) {
                diff = parseInt(diff);
            } else {
                diff = 0;
            }
            let ret = 0;
            if (!isNaN(amo) && !isNaN(diff)) {
                ret = amo + diff;
            }
            return ret;
        },
        doConfirm: function() {
            this.setStep(1);
        },
        close: function() {
            this.$emit("fin");
        },
        addData: function() {
            let datalist = new Array();
            for (let d of this.dlist) {
                let dt = { ...d };
                dt.target_date = this.target_date;
                dt.department_id = d.department.department_id;
                dt.target_amount = d.target_amount_diff;
                dt.man_month = d.man_month_diff;
                datalist.push(dt);
            }

            this.postData(
                this.makeurl("tgt", this.target_date),
                datalist,
                () => {
                    this.$emit("updated");
                    this.setStep(2);
                }
            );
        },
        loaddata: function(targetdate) {
            let params = {
                period: DateUtils.toRequestRangeDate(targetdate, targetdate)
            };
            this.isLoaded = false;

            return this.readDataToArray(this.makeurl("tgt", params), list => {
                this.rawdata = list;
                let nmap = [];
                for (let d of list) {
                    // let dt = d.target_date;
                    // let dtstr = DateUtils.formatDate(dt);
                    let did = d.department_id;
                    let amo = parseInt(d.target_amount);
                    let mam = new Number(d.man_month);
                    if (!nmap[did]) {
                        nmap[did] = {
                            target_amount: amo,
                            man_month: mam
                        };
                    } else {
                        nmap[did].target_amount += amo;
                        nmap[did].man_month += mam;
                    }
                }
                let ndlist = [];
                for (let d of this.dlist) {
                    let obj = nmap[d.department.department_id];
                    let amo = 0;
                    let mam = 0;
                    if (obj) {
                        amo = obj.target_amount;
                        mam = obj.man_month;
                    }
                    d.target_amount = amo;
                    d.target_amount_after = amo;
                    d.man_month = mam;

                    ndlist.push(d);
                }
                this.dlist = ndlist;

                this.isLoaded = true;
            });
        },
        makeInitObj: function(dept) {
            let obj = {
                department: dept,
                target_amount: 0,
                target_amount_diff: 0,
                target_amount_after: 0,
                man_month: 0,
                man_month_diff: 0,
                man_month_after: 0
            };
            obj.calcTargetAmountAfter = val => {
                this.calcTargetAmount(obj, val);
            };
            obj.calcManMonthAfter = val => {
                this.calcManMonth(obj, val);
            };
            return obj;
        },
        initDlist: function() {
            let list = [];
            for (let dept of this.deptlist) {
                if (dept.department_div == 1) {
                    let obj = this.makeInitObj(dept);
                    list.push(obj);
                }
            }
            this.dlist = list;

            this.loaddata(this.model.target_date);
        }
    },
    created: async function() {
        let dp = this.loaddeptlist();
        if (dp instanceof Promise) {
            dp.then(() => {
                this.initDlist();
            });
        } else {
            this.initDlist();
        }
    },
    mounted: function() {
        this.$refs.target_date.focus();
    }
};
</script>

<style scoped>
.target-edit-panel {
    position: relative;
    min-width: 450px;
    padding: 0;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}

/* --- form --- */
.datarow {
    position: relative;
    display: grid;
    grid-template-columns: 100px 150px 1rem 150px 1rem 150px;
    grid-template-areas:
        "dept  amo    amo-plus  amo-diff  amo-eq  amo-after"
        "dept  mam    mam-plus  mam-diff  mam-eq  mam-after"
        "dept  tnote  tnote     tnote     tnote   tnote";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
    align-items: initial;
    z-index: 0;
    padding: 5px;
    border-bottom: 1px solid var(--border-color);
}

.datarow .dept {
    grid-area: dept;
}
.datarow .amo {
    grid-area: amo;
}
.datarow .amo-plus {
    grid-area: amo-plus;
    align-self: center;
}
.datarow .amo-diff {
    grid-area: amo-diff;
}
.datarow .amo-eq {
    grid-area: amo-eq;
    align-self: center;
}
.datarow .amo-after {
    grid-area: amo-after;
}
.datarow .mam {
    grid-area: mam;
}
.datarow .mam-plus {
    grid-area: mam-plus;
    align-self: center;
}
.datarow .mam-diff {
    grid-area: mam-diff;
}
.datarow .mam-eq {
    grid-area: mam-eq;
    align-self: center;
}
.datarow .mam-after {
    grid-area: mam-after;
}
.datarow .tnote {
    grid-area: tnote;
}

/* --- complete --- */
.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}
.item .note {
    padding-top: 5px;
    font-size: 0.75rem;
}
.item .note:before {
    content: "※ ";
}

table.confirm td:not(:first-child) {
    border-left-style: none;
    border-right-style: none;
}
table.confirm tbody {
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
}
</style>
