<template>
    <div class="salestarget">
        <div class="page-header">
            <h1>
                売上目標
                <button class="addButton" @click="openEditor">
                    +
                </button>
            </h1>
            <div>
                <OpSelectFiscalyear
                    v-model="fiscalyear"
                    label="会計年度"
                    @input="changedYear"
                    :company="getCompany()"
                    required
                    hideheader
                />
                <!-- <OpSelectArray
                            v-model="query.fiscalyear"
                            label="会計年度"
                            :modelList="getFiscalyearList()"
                            maxwidth="15rem"
                            @input="search"
                            nosudgest
                        /> -->
            </div>
            <div>
                <OpRadioGroup
                    v-model="perioddiv"
                    v-slot="{ item }"
                    :choices="perioddivlist"
                    :itemKey="item => item.key"
                    @input="changedFilter"
                >
                    {{ item.name }}
                </OpRadioGroup>
            </div>
        </div>
        <div class="body" v-if="isLoaded">
            <div class="tableframe">
                <table>
                    <thead>
                        <tr>
                            <th rowspan="2"></th>
                            <template v-for="(dt, t, ix) in datelist">
                                <th colspan="3" :key="ix" class="cell-north">
                                    {{ t }}
                                </th>
                            </template>
                            <th colspan="2" class="cell-north">
                                {{ startdate | date }} - {{ enddate | date }}
                            </th>
                        </tr>

                        <tr>
                            <template v-for="(dt, t, ix) in datelist">
                                <th
                                    :key="'m' + ix"
                                    class="cell-west cell-south"
                                >
                                    目標金額
                                </th>
                                <th
                                    :key="'k' + ix"
                                    class="cell-center cell-south"
                                >
                                    工数
                                </th>
                                <th
                                    :key="'b' + ix"
                                    class="cell-east cell-south"
                                ></th>
                            </template>
                            <th class="cell-west cell-south">目標金額</th>
                            <th class="cell-east cell-south">工数</th>
                        </tr>
                    </thead>
                    <tbody v-for="d in depts" :key="d.department_id">
                        <tr v-if="d.department_div == 1">
                            <th>{{ d.department_name_short }}</th>
                            <template v-for="(t, ix) in datelist">
                                <td :key="'m' + ix" class="amount cell-west">
                                    <a @click="showDetail(d, t, ix)">
                                        {{
                                            getData(d, ix, "target_amount")
                                                | amount
                                        }}
                                    </a>
                                </td>
                                <td :key="'t' + ix" class="amount cell-center">
                                    {{
                                        getData(d, ix, "man_month")
                                            | amount(0, 2)
                                    }}
                                </td>
                                <td :key="'b' + ix" class="cell-east">
                                    <button
                                        @click="showDetail(d, t, ix)"
                                        style="transform:scale(0.75);"
                                    >
                                        修正
                                    </button>
                                </td>
                            </template>
                            <td class="amount total cell-west">
                                {{
                                    depttotal[d.department_id].target_amount
                                        | amount
                                }}
                            </td>
                            <td class="amount total cell-east">
                                {{
                                    depttotal[d.department_id].man_month
                                        | amount(0, 2)
                                }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>全社</th>
                            <template v-for="(t, ix) in datelist">
                                <td :key="'m' + ix" class="amount cell-west">
                                    {{
                                        getDateTotal(ix, "target_amount")
                                            | amount
                                    }}
                                </td>
                                <td :key="'t' + ix" class="amount cell-center">
                                    {{
                                        getDateTotal(ix, "man_month")
                                            | amount(0, 2)
                                    }}
                                </td>
                                <td :key="'b' + ix" class="cell-east"></td>
                            </template>
                            <td class="amount total cell-west">
                                {{ total.target_amount | amount }}
                            </td>
                            <td class="amount total cell-east">
                                {{ total.man_month | amount(0, 2) }}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <OpModal
            v-if="showEditor"
            title="目標登録"
            @close="showEditor = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <TargetDeptsEditor
                mode="create"
                v-model="newModel"
                @fin="close"
                @step="step"
                @updated="createdData"
            ></TargetDeptsEditor>
        </OpModal>

        <OpModal
            v-if="isShowDetail"
            title="目標詳細"
            @close="isShowDetail = false"
            v-slot="{ close }"
        >
            <TargetDetailList
                v-model="selectedData"
                @fin="close"
                @showEditor="doShowOneEditor"
            ></TargetDetailList>
        </OpModal>

        <OpModal
            v-if="isShowOneEditor"
            title="目標修正"
            @close="isShowOneEditor = false"
            v-slot="{ close }"
        >
            <TargetOneEditor
                v-model="selectedOneData"
                @fin="close"
                @updated="createdData"
            ></TargetOneEditor>
        </OpModal>
    </div>
</template>

<script>
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import OpModal from "@/components/OpModal.vue";
import TargetDeptsEditor from "@/views/salestarget/TargetDeptsEditor.vue";
import TargetOneEditor from "@/views/salestarget/TargetOneEditor.vue";
import TargetDetailList from "@/views/salestarget/TargetDetailList.vue";

import DateUtils from "@/DateUtils";

export default {
    name: "TargetList",
    components: {
        OpSelectFiscalyear,
        OpRadioGroup,
        TargetDeptsEditor,
        TargetOneEditor,
        TargetDetailList,
        OpModal
    },
    props: {},
    computed: {
        newModel: function() {
            return { target_date: this.startdate };
        }
    },
    data: function() {
        let comp = this.getCompany();
        let fh = DateUtils.getFiscalYearAndHalfsignToday(comp);
        let dts = DateUtils.getFiscalRangeDate(
            comp,
            fh.fiscalyear,
            fh.halfsign
        );
        return {
            depts: [],
            fiscalyear: fh.fiscalyear,
            perioddiv: fh.halfsign,
            startdate: dts.start,
            enddate: dts.end,
            refreshCount: 0,
            perioddivlist: [
                { key: 0, name: "通期" },
                { key: 1, name: "上期" },
                { key: 2, name: "下期" }
            ],
            stats: null,
            rawdata: null,
            datelist: null,
            datalist: null,
            depttotal: null,
            datetotal: null,
            total: null,
            isLoaded: false,
            showEditor: false,
            selectedData: null,
            isShowDetail: false,
            isShowOneEditor: false,
            selectedOneData: null
        };
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    created: async function() {
        // let dp = this.loaddeptlist();
        // if (dp instanceof Promise) {
        //     dp.then(() => {
        //         this.depts = this.deptlist;
        //     });
        // } else {
        //     this.depts = this.deptlist;
        // }
    },
    methods: {
        openEditor: function() {
            this.showEditor = true;
        },
        changedFilter: function() {
            let y = null;
            if (this.fiscalyear.key) {
                y = this.fiscalyear;
            } else {
                y = { key: this.fiscalyear };
            }
            this.changedYear(y);
        },
        changedYear: function(year) {
            let dts = DateUtils.getFiscalRangeDate(
                this.getCompany(),
                year.key,
                this.perioddiv
            );
            this.startdate = dts.start;
            this.enddate = dts.end;
            this.loaddata();
        },
        showDetail: function(dept, dt, dtstr) {
            this.selectedData = {
                department: dept,
                target_date: dt,
                data: this.filterRawData(dept.department_id, dtstr)
            };
            this.isShowDetail = true;
        },
        filterRawData: function(department_id, target_date_str) {
            return this.rawdata.filter(d => {
                return (
                    DateUtils.formatDate(d.target_date) == target_date_str &&
                    d.department_id == department_id
                );
            });
        },
        loaddata: function() {
            this.loaddepts(this.loaddata2);
        },
        loaddata2: function() {
            let params = {
                period: DateUtils.toRequestRangeDate(
                    this.startdate,
                    this.enddate
                )
            };
            this.isLoaded = false;

            return this.readDataToArray(this.makeurl("tgt", params), list => {
                this.rawdata = list;
                let nmap = [];
                let dlist = {};
                for (let d of list) {
                    let dt = d.target_date;
                    let dtstr = DateUtils.formatDate(dt);
                    // if (dlist.indexOf(dtstr) < 0) {
                    dlist[dtstr] = dt;
                    // }

                    let did = d.department_id;
                    if (!nmap[did]) {
                        nmap[did] = [];
                    }
                    let tmap = nmap[did];
                    if (tmap[dtstr]) {
                        tmap[dtstr].push(d);
                    } else {
                        tmap[dtstr] = [d];
                    }
                }
                this.datelist = dlist;

                let ddlist = [];
                let tvlist = [];
                let thlist = [];
                let totalamo = 0;
                let totalmam = 0;
                for (let dept of this.depts) {
                    let did = dept.department_id;
                    let ttt = [];
                    let tmap = nmap[did];
                    let tamo = 0;
                    let tmam = 0;
                    for (let t in dlist) {
                        let amo = 0;
                        let mam = 0;
                        if (tmap && tmap[t]) {
                            for (let a of tmap[t]) {
                                let at = parseInt(a.target_amount);
                                let am = new Number(a.man_month);
                                amo += at;
                                mam += am;
                                // tamo += at;
                                // tmam += am;
                            }
                        }
                        ttt[t] = {
                            target_amount: amo,
                            man_month: mam
                        };

                        let tv = tvlist[t];
                        if (tv) {
                            tv.target_amount += amo;
                            tv.man_month += mam;
                        } else {
                            tvlist[t] = {
                                target_amount: amo,
                                man_month: mam
                            };
                        }
                        tamo += amo;
                        tmam += mam;
                    }

                    ddlist[did] = ttt;

                    thlist[did] = {
                        target_amount: tamo,
                        man_month: tmam
                    };
                    totalamo += tamo;
                    totalmam += tmam;
                }
                this.total = {
                    target_amount: totalamo,
                    man_month: totalmam
                };
                this.datalist = ddlist;
                this.depttotal = thlist;
                this.datetotal = tvlist;

                if (this.selectedOneData) {
                    let dept = this.selectedData.department;
                    let dtstr = DateUtils.formatDate(
                        this.selectedData.target_date
                    );
                    this.selectedData.data = this.filterRawData(
                        dept.department_id,
                        dtstr
                    );
                }

                this.isLoaded = true;
            });
        },
        getData: function(d, t, key) {
            let ret = 0;
            let tlist = this.datalist[d.department_id];
            if (tlist && tlist[t]) {
                ret = tlist[t][key];
            }
            return ret;
        },
        getDateTotal: function(t, key) {
            let ret = 0;
            if (this.datetotal[t]) {
                ret = this.datetotal[t][key];
            }
            return ret;
        },
        doShowOneEditor: function(onedata) {
            this.selectedOneData = onedata;
            this.isShowOneEditor = true;
        },
        createdData: function() {
            this.loaddata();
            if (this.selectedOneData) {
                let dept = this.selectedData.department;
                let dtstr = DateUtils.formatDate(this.selectedData.target_date);
                this.selectedData.data = this.filterRawData(
                    dept.department_id,
                    dtstr
                );
            }
        },
        initdeptlist: function(deptlist) {
            return deptlist;
        },
        loaddepts: function(func) {
            this.loading = true;
            let params = {
                period: DateUtils.toRequestRangeDate(
                    this.startdate,
                    this.enddate
                )
            };
            return this.readDataToArray(this.makeurl("dept", params), list => {
                this.depts = this.initdeptlist(list);
                if (func) {
                    func();
                }
            });
        }
    },
    mounted: function() {
        this.loaddata();
    }
};
</script>

<style scoped>
.salestarget .page-header {
    z-index: 1;
    height: 4rem;
}

.salestarget .body {
    position: relative;
    width: 100%;
    padding: 0px 10px;
    z-index: 0;
}
/* .salestarget .tableframe {
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 5rem);
    overflow-y: auto;
} */
.tableframe {
    text-align: center;
}
.st-block {
    padding-bottom: 2rem;
}
.st-block table td {
    text-align: right;
    font-size: 0.9rem;
}
.outsourcing-cost,
.purchase-amount,
.commission-paid {
    font-size: 0.8rem;
}
.achievement-rate {
    border-right-style: double;
}
.dept2 {
    border-left-style: double;
    border-left-width: 3px;
}
.soposts > div {
    display: inline-block;
    padding: 0px 20px;
    transform: scale(0.75);
}

table th {
    text-align: center;
    vertical-align: baseline;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}
table td.total {
    background-color: var(--main-context-bg-color);
    filter: var(--total-filter);
}
table {
    border-right: 1px solid var(--border-color);
}
table tfoot {
    border-top: 3px double var(--border-color);
}
table thead tr:nth-child(1) > * {
    position: sticky;
    top: 4rem;
    /* background-color: #eee; */
    z-index: 2;
}
table thead tr:nth-child(2) > * {
    position: sticky;
    top: calc(4rem + 23px);
    /* background-color: #eee; */
    z-index: 2;
}
table thead tr:first-child > *:first-child {
    position: sticky;
    top: 4rem;
    left: 0;
    z-index: 3;
}
table tfoot tr > *:first-child,
table tbody tr > *:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}
/* --- */
.cell-west {
    border-right-style: none;
}
.cell-center {
    border-style: solid none;
}
.cell-east {
    border-left-style: none;
}
.cell-north {
    border-bottom-style: none;
}
.cell-south {
    border-top-style: none;
}
</style>
