<template>
    <div class="target-detail-panel">
        <div class="header content">
            <div class="dept-id">{{ department.department_id }}</div>
            <div class="dept-code">{{ department.department_code }}</div>
            <div class="dept-name">{{ department.department_name_short }}</div>
            <div class="dept-period">
                {{ department.start_date | date }} ~
                {{ department.end_date | date }}
            </div>
            <div class="target-date">{{ target_date | date }}</div>
        </div>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>目標金額</th>
                        <th>工数</th>
                        <th>備考</th>
                        <th>登録日時</th>
                        <th>更新日時</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, ix) in dlist" :key="ix">
                        <th>{{ d.target_sequence }}</th>
                        <td class="amount">{{ d.target_amount | amount }}</td>
                        <td class="amount">{{ d.man_month | amount(0, 2) }}</td>
                        <td>{{ d.target_note }}</td>
                        <td class="sysinfo">
                            <div>{{ d.create_timestamp | datetime }}</div>
                            <div>{{ d.create_account_id }}</div>
                        </td>
                        <td class="sysinfo">
                            <div>{{ d.update_timestamp | datetime }}</div>
                            <div>{{ d.update_account_id }}</div>
                        </td>
                        <td>
                            <button class="button-edit" @click="showEditor(d)">
                                修正
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <th>合計</th>
                    <td class="amount">{{ target_amount_total | amount }}</td>
                    <td class="amount">{{ man_month_total | amount(0, 2) }}</td>
                    <td></td>
                    <td class="sysinfo"></td>
                    <td class="sysinfo"></td>
                    <td>
                        <button class="addButton" @click="showEditor(null)">
                            +
                        </button>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
// // import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
// import EditorMixin from "@/views/EditorMixin.vue";
// import OpDateTextField from "@/components/OpDateTextField.vue";
// import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
// import OpNumberTextField from "@/components/OpNumberTextField.vue";
// import OpTextField from "@/components/OpTextField.vue";

export default {
    name: "TargetDetailList",
    // mixins: [EditorMixin],
    // components: {
    //     OpDateTextField,
    //     OpSelectDepartment,
    //     OpNumberTextField,
    //     OpTextField
    // },
    model: {
        prop: "model"
    },
    props: {
        model: Object
    },
    data: function() {
        return {
            dlist: this.initModel(),
            target_date: this.model.target_date,
            department: this.model.department,
            target_amount_total: 0,
            man_month_total: 0
        };
    },
    watch: {
        "model.data": function() {
            this.dlist = this.initModel();
            this.calcTotal();
        }
    },
    methods: {
        initModel: function() {
            let ret = this.model.data;
            return ret;
        },
        showEditor: function(d) {
            if (d) {
                d.method = "update";
            } else {
                d = {};
                d.target_date = this.target_date;
                d.department_id = this.department.department_id;
                d.method = "create";
                d.target_amount = 0;
                d.man_month = 0;
            }
            d.department = this.department;
            d.target_amount_after = this.target_amount_total;
            d.target_amount_total = this.target_amount_total - d.target_amount;
            d.man_month_after = this.man_month_total;
            d.man_month_total = this.man_month_total - d.man_month;
            this.$emit("showEditor", d);
        },
        getDeptName: function(id) {
            console.log("★★★ : " + typeof this.deptlist[id]);
            for (let did in this.deptlist) {
                console.log("★★★ did: " + did);
            }
            return this.deptlist[id].department_name_short;
        },

        doConfirm: function() {
            this.setStep(1);
        },
        close: function() {
            this.$emit("fin");
        },
        addData: function() {
            this.d.department_id = this.d.target_department.department_id;
            this.postData(this.makeurl("tgt"), this.d, dt => {
                this.d = dt;
                this.$emit("updated", dt);
                this.setStep(2);
            });
        },
        updateData: function() {
            this.d.department_id = this.d.target_department.department_id;
            this.putData(
                this.makeurl(
                    "tgt",
                    this.d.target_date,
                    this.d.department_id,
                    this.d.target_sequence
                ),
                this.d,
                dt => {
                    this.d = dt;
                    this.$emit("updated", dt);
                    this.setStep(2);
                }
            );
        },
        calcTotal: function() {
            this.target_amount_total = 0;
            this.man_month_total = 0;
            for (const d of this.model.data) {
                this.target_amount_total += parseInt(d.target_amount);
                this.man_month_total += new Number(d.man_month);
            }
        }
    },
    created: function() {
        this.calcTotal();
    }
    // mounted: function() {
    //     this.$refs.target_date.focus();
    // }
};
</script>

<style scoped>
table {
    border-right: 1px solid var(--border-color);
}
table th {
    text-align: center;
    vertical-align: middle;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}
table tfoot {
    border-top: 3px double var(--border-color);
}
/* --- */
.header .dept-id {
    display: inline-block;
    font-size: 0.75rem;
    color: var(--title-text-color);
}
.header .dept-id::after {
    content: " : ";
}
.header .dept-code {
    display: inline-block;
    padding-right: 0.5rem;
}
.header .dept-name {
    display: inline-block;
}
.header .dept-period {
    display: inline-block;
    font-size: 0.75rem;
}
.header .dept-period::before {
    content: "(";
    padding-left: 1rem;
}
.header .dept-period::after {
    content: ")";
}

.header .target-date {
    padding-top: 10px;
    font-size: 1.25rem;
}
.button-edit {
    transform: scale(0.75);
}
/* --- */
.sysinfo {
    font-size: 0.75rem;
}
</style>
