<template>
    <div class="view-page loader-home" v-if="hasTicket('ldr')">
        <div class="page-header">
            <h1>
                Data Loader (α版)
                <button
                    class="addButton"
                    @click="showEditor()"
                    v-if="hasTicket('ldr')"
                >
                    ＋
                </button>
            </h1>
        </div>
        <OpModal
            title="Data Loader"
            v-if="showEditDialog"
            @close="showEditDialog = false"
            v-slot="{ close, step }"
            :steps="['入力', '完了']"
        >
            <DataLoader @fin="close" @step="step" />
        </OpModal>
    </div>
</template>

<script>
import DataLoader from "@/views/loader/DataLoader.vue";

// const axios = require("axios");

export default {
    name: "DataLoaderHome",
    components: {
        DataLoader
    },
    data: function() {
        return {
            showEditDialog: false
        };
    },
    methods: {
        showEditor: function() {
            this.showEditDialog = true;
        }
    }
};
</script>
