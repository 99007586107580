<template>
    <div class="target-edit-panel">
        <div class="header content" v-if="!isStep(2)">
            <div class="dept">
                <div class="dept-id">{{ d.department.department_id }}</div>
                <div class="dept-code">{{ d.department.department_code }}</div>
                <div class="dept-name">
                    {{ d.department.department_name_short }}
                </div>
                <div class="dept-period">
                    {{ d.department.start_date | date }} ~
                    {{ d.department.end_date | date }}
                </div>
            </div>

            <div class="item target-date">
                <OpDateTextField
                    label="目標日"
                    v-model="d.target_date"
                    name="target_date"
                    readonly
                />
            </div>
            <div class="update-header" v-if="d.method == 'update'">
                <div class="item seqno">No. {{ d.target_sequence }}</div>
                <div class="sysinfo" v-if="d.method == 'update'">
                    <div class="item">
                        <div class="title">作成</div>
                        <div class="value">
                            <div>
                                {{ d.create_timestamp | datetime }}
                            </div>
                            <div class="value">
                                {{ d.create_account_id }}
                            </div>
                        </div>
                    </div>
                    <div class="item" v-if="d.update_timestamp">
                        <div class="title">変更</div>
                        <div class="value">
                            <div>
                                {{ d.update_timestamp | datetime }}
                            </div>
                            <div class="value">
                                {{ d.update_account_id }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form" v-if="isStep(0)">
            <div class="target-edit-form">
                <div class="datarow">
                    <fieldset>
                        <legend>目標金額</legend>
                        <div class="amo-fieldset">
                            <div class="amo">
                                <OpNumberTextField
                                    label="その他合計"
                                    v-model="d.target_amount_total"
                                    name="target_amount_total"
                                    readonly
                                />
                            </div>
                            <div class="amo-plus">
                                +
                            </div>
                            <div class="amo-diff">
                                <OpNumberTextField
                                    label="追加金額"
                                    v-model="d.target_amount"
                                    :max="999999999999 - d.target_amount_total"
                                    :min="-1 * d.target_amount_total"
                                    name="target_amount"
                                    @changed-valid="changedValid"
                                    @liveinput="calcTargetAmount"
                                    disabledAutowidth
                                />
                            </div>
                            <div class="amo-eq">
                                =
                            </div>
                            <div class="amo-after">
                                <OpNumberTextField
                                    label="目標金額（計）"
                                    v-model="d.target_amount_after"
                                    name="target_amount_after"
                                    readonly
                                />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>工数</legend>
                        <div class="mam-fieldset">
                            <div class="mam">
                                <OpNumberTextField
                                    label="その他合計"
                                    v-model="d.man_month_total"
                                    name="man_month_total"
                                    :decimals="2"
                                    readonly
                                />
                            </div>
                            <div class="mam-plus">
                                +
                            </div>
                            <div class="mam-diff">
                                <OpNumberTextField
                                    label="追加工数"
                                    v-model="d.man_month"
                                    :max="99999.99 - d.man_month_total"
                                    :min="-1 * d.man_month_total"
                                    name="man_month"
                                    :decimals="2"
                                    @liveinput="calcManMonth"
                                    @changed-valid="changedValid"
                                    disabledAutowidth
                                />
                            </div>
                            <div class="mam-eq">
                                =
                            </div>
                            <div class="mam-after">
                                <OpNumberTextField
                                    label="工数（計）"
                                    v-model="d.man_month_after"
                                    name="man_month_after"
                                    :decimals="2"
                                    readonly
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="tnote">
                    <OpTextField
                        label="備考"
                        v-model="d.target_note"
                        name="target_note"
                        maxlength="80"
                        @changed-valid="changedValid"
                    />
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)">
            <table class="confirm">
                <tr>
                    <th>目標金額</th>
                    <td class="amount">{{ d.target_amount_total | amount }}</td>
                    <td>
                        <span v-if="d.target_amount < 0">
                            -
                        </span>
                        <span v-else>
                            +
                        </span>
                    </td>
                    <td class="amount">{{ d.target_amount | amount }}</td>
                    <td>=</td>
                    <td class="amount">{{ d.target_amount_after | amount }}</td>
                </tr>
                <tr>
                    <th>部署工数</th>
                    <td class="amount">{{ d.man_month_total | amount }}</td>
                    <td>
                        <span v-if="d.man_month < 0">
                            -
                        </span>
                        <span v-else>
                            +
                        </span>
                    </td>
                    <td class="amount">{{ d.man_month | amount }}</td>
                    <td>=</td>
                    <td class="amount">{{ d.man_month_after | amount }}</td>
                </tr>

                <tr>
                    <th>備考</th>
                    <td colspan="5">{{ d.target_note }}</td>
                </tr>
            </table>

            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click="updateData" v-if="d.method == 'update'">
                        登録
                    </button>
                    <button @click="addData" v-else>
                        追加
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            登録しました。

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import EditorMixin from "@/views/EditorMixin.vue";
import OpNumberTextField from "@/components/OpNumberTextField.vue";
import OpTextField from "@/components/OpTextField.vue";

export default {
    name: "TargetOneEditor",
    mixins: [EditorMixin],
    components: {
        OpNumberTextField,
        OpTextField
    },
    model: {
        prop: "model"
    },
    props: {
        model: Object,
        mode: String
    },
    data: function() {
        return {
            d: this.initModel()
        };
    },
    methods: {
        initModel: function() {
            let ret = { ...this.model };
            return ret;
        },
        doConfirm: function() {
            this.setStep(1);
        },
        close: function() {
            this.$emit("fin");
        },
        calcTargetAmount: function(val) {
            this.d.target_amount_after = this.calcAfter(
                this.d.target_amount_total,
                val
            );
        },
        calcManMonth: function(val) {
            this.d.man_month_after = this.calcAfter(
                this.d.man_month_total,
                val
            );
        },
        calcAfter: function(amo, val) {
            if (amo) {
                amo = new Number(amo);
            } else {
                amo = 0;
            }
            // let diff = obj.target_amount_diff;
            let diff = val;
            if (diff) {
                diff = new Number(diff);
            } else {
                diff = 0;
            }
            let ret = 0;
            if (!isNaN(amo) && !isNaN(diff)) {
                ret = amo + diff;
            }
            return ret;
        },
        addData: function() {
            this.d.department_id = this.d.department.department_id;
            this.postData(this.makeurl("tgt"), this.d, dt => {
                // this.d = dt;
                this.$emit("updated", dt);
                this.setStep(2);
            });
        },
        updateData: function() {
            this.d.department_id = this.d.department.department_id;
            this.putData(
                this.makeurl(
                    "tgt",
                    this.d.target_date,
                    this.d.department_id,
                    this.d.target_sequence
                ),
                this.d,
                dt => {
                    // this.d = dt;
                    this.$emit("updated", dt);
                    this.setStep(2);
                }
            );
        }
    },
    mounted: function() {
        // this.$refs.target_date.focus();
    }
};
</script>

<style scoped>
.so-edit-panel {
    position: relative;
    min-width: 450px;
    padding: 0;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}
/* --- header --- */

/* --- form --- */
/* .datarow {
    position: relative;
    display: grid;
    grid-template-columns: 150px 1rem 150px 1rem 150px;
    grid-template-areas:
        "  amo    amo-plus  amo-diff  amo-eq  amo-after"
        "  mam    mam-plus  mam-diff  mam-eq  mam-after"
        "  tnote  tnote     tnote     tnote   tnote";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
    align-items: initial;
    z-index: 0;
    padding: 5px;
    border-bottom: 1px solid #ccc;
} */

.amo-fieldset {
    position: relative;
    display: grid;
    grid-template-columns: 200px 30px 200px 20px 200px;
    grid-template-areas: " amo  amo-plus  amo-diff  amo-eq    amo-after";
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    align-items: initial;
    z-index: 0;
}
.mam-fieldset {
    position: relative;
    display: grid;
    grid-template-columns: 200px 30px 200px 20px 200px;
    grid-template-areas: " mam  mam-plus  mam-diff  mam-eq    mam-after";
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    align-items: initial;
    justify-items: stretch;
    z-index: 0;
}

.datarow .dept {
    grid-area: dept;
}
.datarow .amo {
    grid-area: amo;
}
.datarow .amo-plus {
    grid-area: amo-plus;
    align-self: center;
    justify-self: center;
}
.datarow .amo-diff {
    grid-area: amo-diff;
}
.datarow .amo-eq {
    grid-area: amo-eq;
    align-self: center;
    justify-self: center;
}
.datarow .amo-after {
    grid-area: amo-after;
}
.datarow .mam {
    grid-area: mam;
}
.datarow .mam-plus {
    grid-area: mam-plus;
    align-self: center;
    justify-self: center;
}
.datarow .mam-diff {
    grid-area: mam-diff;
}
.datarow .mam-eq {
    grid-area: mam-eq;
    align-self: center;
    justify-self: center;
}
.datarow .mam-after {
    grid-area: mam-after;
}
.datarow .tnote {
    grid-area: tnote;
}
/* --- complete --- */
.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}
.item .note {
    padding-top: 5px;
    font-size: 0.75rem;
}
.item .note:before {
    content: "※ ";
}

/* --- */
.dept .dept-id {
    display: inline-block;
    font-size: 0.75rem;
    color: var(--text-weak-color);
}
.dept .dept-id::after {
    content: " : ";
}
.dept .dept-code {
    display: inline-block;
    padding-right: 0.5rem;
}
.dept .dept-name {
    display: inline-block;
}
.dept .dept-period {
    display: inline-block;
    font-size: 0.75rem;
}
.dept .dept-period::before {
    content: "(";
    padding-left: 1rem;
}
.dept .dept-period::after {
    content: ")";
}
/* --- */
.target-date {
    display: inline-block;
}
.update-header {
    display: inline-block;
    vertical-align: middle;
    padding-left: 2rem;
}
.update-header > * {
    display: inline-block;
    vertical-align: top;
    padding: 1rem 0;
}
/* --- */
.sysinfo {
    padding-left: 1rem;
}
.sysinfo .item {
    display: inline-block;
    font-size: 0.75rem;
    padding-left: 1rem;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
}

fieldset {
    border-radius: 4px;
    border: 1px solid var(--border-color);
    margin: 0 1rem 1rem 1rem;
}
fieldset legend {
    font-size: 0.75rem;
    padding: 0 0.5rem;
}
table.confirm td:not(:first-child) {
    border-right-style: none;
    border-left-style: none;
}
</style>
